.footer {
    background: var(--footer-color);
    margin-bottom: 15px;
}

.footer-col {
    padding: 50px 0 30px;
}

.footer-col .footer-service {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
}

.footer-col .footer-service .service-item {
    display: flex;
    align-items: center;
    border-radius: 5px;
    background: #1E387B;
    padding: 5px;
}

.footer-col .footer-service .service-item .service-icon {
    margin-right: 8px;
}

.footer-col .footer-service .service-item .service-icon img {
    width: 50px;
    filter: invert(1);
}

.footer-col .footer-service .service-item .service-time {
    color: var(--page-text);
    width: 100%;
    line-height: 1;
}

.footer-col .footer-service .service-item .service-time .time-title {
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 3px;
}

.footer-col .footer-service .service-item .service-time .time-number {
    font-size: 12px;
    margin-bottom: 3px;
}

.footer-col .footer-service .service-item .service-time .progress {
    height: 12px;
    padding: 2px 3px;
    border-radius: 25px;
    margin-bottom: 0;
    overflow: hidden;
}

.footer-col .footer-download {
    background: var(--c-black);
    border: 2px solid #6CCF08;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin: auto;
}

.footer-col .footer-download img {
    filter: invert(79%) sepia(39%) saturate(3384%) hue-rotate(42deg) brightness(92%) contrast(96%);
    width: 25px;
    margin: auto;
}


.footer-col .footer-download span {
    color: #6CCF08;
    font-size: 10px;
    font-weight: 600;
    margin-top: 8px;
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}

.footer-col .footer-nav {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 7px;
}

.footer-col .footer-nav a {
    color: var(--secondary-color);
    font-size: 13px;
    font-weight: 600;
}

/* provider */
.footer__provider .provider-header {
    background: var(--navbar-color);
    color: var(--secondary-color);
    font-size: 18px;
    padding: 8px 0;
    text-align: center;
    font-weight: 600;
}

.footer__provider .provider-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 15px;
}

.footer__provider .provider-list .provider-icon {
    background: transparent;
    margin: 3px;
    padding: 3px;
    min-width: 95px;
    border-radius: 10px;
}

.footer__provider .provider-list .provider-icon img {
    width: 100%;
    height: 30px;
    -o-object-fit: contain;
    object-fit: contain;
    cursor: pointer;
}

/* provider end */



.footer__trademark {
    background: var(--footer-color);
    text-align: center;
    color: var(--footer-text);
    font-size: 12px;
    padding: 20px 0;
    font-weight: 500;
}


.footermobile{
    display: none;
    position: sticky;
    bottom: 0;
    z-index: 10;
}
@media screen and (max-width: 991px) {
  
    .footermobile{
        display: block;
    }
    .footermobile .footer__trademark {
        font-size: 10px;

    }
    
}

@media screen and (max-width:991px) {

    .footer .footer__provider, .footer .footer__trademark {
        display: none;
    }
}