.header {
    position: sticky;
    top: 0;
    z-index: 10;
    display: block;
}



.header .header__top {
    padding: 10px 0;
    background: var(--navbar-color);
    font-family: 'Chakra Petch';
    color: var(--navbar-text);
    box-shadow: 0px 0px 20px -2px rgba(0, 0, 0, 0.8);
}


.header-top-date .header-time {
    font-size: 13px;
    font-weight: 600;
    line-height: 1;
    color: #D2820B;
}

.header .header__top .header-marquee {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    background: #1E387B;
    padding: 5px 15px;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
}

.header .header__top .header-marquee .marquee-icon {
    background: #1E387B;
    padding: 5px;
    padding-left: 20px;
    z-index: 10;
    width: 45px;
    position: absolute;
    left: 0;
}


.header .header__top .header-marquee .marquee-icon img {
    width: 15px;
    display: flex;
    filter: invert(1);

}

.header .header__top .header-marquee .marquee {
    font-size: 12px;
    font-weight: 600;
    width: 100vw;
    display: inline-block;
    text-align: initial;
    animation: marquee 22s linear infinite;
    margin-bottom: 0;
}

@keyframes marquee {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

.header .header__top .header-menu {
    display: flex;
    align-items: center;
    grid-gap: 10px;
}

.header .header__top .header-menu .menu-icon img {
    width: 18px;
    filter: invert(1);
}

.header .header__top .header-menu .menu-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    background: #1E387B;
    padding: 5px 10px;
    border-radius: 5px;
}

.header .header__top .header-menu .menu-item .item-icon {
    margin-right: 3px;
}

.header .header__top .header-menu .menu-item .item-name {
    font-size: 12px;
    font-weight: 600;
    color: var(--header-text);
}

.header .header__top .header-menu .menu-item .item-icon img {
    width: 18px;
    display: flex;
}

/* header mid */

.header .header__mid {
    background: var(--header-color);
    color: var(--header-text);
    padding: 15px 0;
}

.header .header__mid .mid-profile {
    display: flex;
    align-items: center;
}


.header .header__mid .mid-link {
    display: flex;
    align-items: center;
    grid-gap: 20px;
}


.header .header__mid .mid-link .link-item {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    position: relative;
}

.header .header__mid .mid-link .link-item .link-icon img {
    width: 16px;
    height: 16px;
    -o-object-fit: contain;
    object-fit: contain;
    filter: invert(1);
}

.header .header__mid .mid-link .link-item .link-title {
    color: var(--c-white);
    font-size: 14px;
    font-weight: 600;
    line-height: 1;

}

.header .header__mid .mid-link a {
    text-decoration: none;
}

.header .header__mid .mid-profile .profile-greetings {
    font-size: 16px;
    margin-bottom: 13px;
}

.header .header__mid .mid-profile .profile-greetings span {
    font-weight: 600;
}

.header .header__mid .mid-logo img {
    width: 100%;
    height: 60px;
    -o-object-fit: contain;
    object-fit: contain;
}

.header .header__mid .mid-form {
    display: flex;
    justify-content: flex-end;
}

.header .header__mid .mid-form .form-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 10px;
}


.header .header__mid .mid-form .form-button button {
    border-radius: 5px;
    background: var(--secondary-color);
    color: var(--c-black);
    padding: 3px 10px;
    border: 2px solid var(--secondary-color);
    font-weight: 700;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header .header__mid .mid-form .form-button button img {
    margin-right: 5px;
    width: 20px;
}


.header .header__mid .mid-form .form-button button.btn-register {
    background: var(--c-black);
    color: var(--secondary-color);
}


.header .header__mid .mid-form .form-button button.btn-register img {
    filter: invert(54%) sepia(21%) saturate(5681%) hue-rotate(12deg) brightness(97%) contrast(91%);
}

/* header mid end */


.header-nav .navbar {
    background: var(--navbar-color);
    padding: 0;
    filter: drop-shadow(0px 0px 20px 0px black);
    color: #fff;
}

.header-nav .navbar .navbar-nav .nav-item {
    padding: 13px 15px;
}

.header-nav .navbar .navbar-nav .nav-item .nav-link-popular {
    background: linear-gradient(to bottom, #FF7F09, #9F0811);
}


.header-nav .navbar .navbar-nav .nav-link {
    font-weight: 600;
    height: 100%;
    min-width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 7px;
    background: var(--secondary-color);
    color: var(--secondary-text);
    border-radius: 5px;
    padding: 7px 15px;
}

.header-nav .navbar .navbar-nav .nav-link img {
    width: 20px;
    height: 20px;
    -o-object-fit: contain;
    object-fit: contain;
    filter: invert(1);
}


.header-nav .navbar .navbar-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: row;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.header-nav .navbar .navbar-nav .nav-link-slot {
    background: linear-gradient(to bottom, #00FFC2, #0029FF);
}

.header-nav .navbar .navbar-nav .nav-link-livegames {
    background: linear-gradient(to bottom, #DD8501, #9D0974);
}

.header-nav .navbar .navbar-nav .nav-link-casino {
    background: linear-gradient(to bottom, #E777CE, #4A00E8);
}

.header-nav .navbar .navbar-nav .nav-link-sport {
    background: linear-gradient(to bottom, #FFC045, #EB3901);
}

.header-nav .navbar .navbar-nav .nav-link-arcade {
    background: linear-gradient(to bottom, #0D78F5, #002857);
}

/* mobile */
.header-mobile {
    display: none;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #002857;
}

@media screen and (max-width: 1080px) {
    .header-mobile__top {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 8px 12px;
    }

    .header-mobile__top .mobile-logo img {
        width: 100px;
        height: 50px;
        -o-object-fit: contain;
        object-fit: contain;
    }

    .header-mobile__top .mobile-button .mobile-button--menu img {
        width: 25px;
        height: 25px;
        -o-object-fit: contain;
        object-fit: contain;
        filter: invert(1);
        margin-right: 15px;
        cursor: pointer;
    }
}

/* mobile end */


@media only screen and (max-width: 575px) {
    .header-nav {
        display: none;
    }
}



@media screen and (max-width:991px) {
    .header {
        display: none;
    }

    .header-mobile {
        display: block;
    }
}