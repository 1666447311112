.seo-content {

    background: var(--primary-color);
    border-radius: 5px;
    padding: 10px 15px;
    color: var(--primary-text);
    font-size: 14px;

}

.seo-content table {
    border: 2px solid #fff;
}

.table-color td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

table .table-color {
    background-color: rgb(0, 16, 36);
}

.home__bank {
    background: var(--primary-color);
    margin: 20px 0;
    padding: 20px 0;
    margin-bottom: 30px;
    
}

.bank-holder {
    background: var(--primary-color);
    padding: 2px;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    border: 2px solid #1E387B;
}

.bank-holder .bank-img img {
    max-width: 100%;
    height: 30px;
    -o-object-fit: contain;
    object-fit: contain;
    filter: drop-shadow(0 0 0.5px white);
}

.bank-holder .bank-status.online {
    color: #00D348;
}

.bank-holder .bank-status {
    font-size: 12px;
    font-weight: 600;
    padding: 2px 8px;
    margin-top: 7px;
    line-height: 1;
}

.bank-holder .bank-img img {
    max-width: 100%;
    height: 30px;
    -o-object-fit: contain;
    object-fit: contain;
    filter: drop-shadow(0 0 0.5px white);
}

.bank-header {
    color: var(--secondary-color);
    text-align: center;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 20px;
    line-height: 1;
}