.home__jackpot {
    padding-bottom: 20px;
    padding-top: 20px;
}

.home__jackpot .jackpot-gif {
    background-image: url(/public/assets/img/jackpot.gif);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    height: 170px;
}

.home__jackpot .jackpot-gif .jackpot-amount {
    font-size: 32px;
    font-weight: 600;
    color: var(--jackpot-text);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 15px;
    left: 15px;
    padding-right: 50px;
    letter-spacing: 3px;
    font-family: advanced_dot_digital-7regular;
}


@media screen and (max-width: 1080px) {
    .home__jackpot .jackpot-gif .jackpot-amount {
        font-size: 13px !important;
        font-family: advanced_dot_digital-7regular;

        top: 11px !important;
    }
}

.game-container {
    background: var(--primary-color);
    border-radius: 5px;
    overflow: hidden;
}

.game-container__header {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background: var(--header-color);
}

.game-container__header .game-title {
    display: flex;
    align-items: center;
}

.game-container__header .game-title .icon {
    margin-right: 10px;
}

.game-container__header .game-title .icon img {
    width: 30px;
    filter: invert(54%) sepia(21%) saturate(5681%) hue-rotate(12deg) brightness(97%) contrast(91%);
}


.game-container__header .game-title h6 {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 600;
    color: var(--secondary-color);
    white-space: nowrap;
}

.game-container__header .game-action {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.game-container__header .game-action .action-button {
    margin-right: 5px;
}

.game-container__header .game-action .action-button .btn-custom-sm {
    border-radius: 5px;
    font-size: 12px;
    padding: 3px 10px;
    font-weight: 700;
}

.btn-custom-sm {
    background: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    padding: 6px 12px;
    font-size: 13px;
    font-weight: 600;
    border-radius: 5px;
    color: var(--secondary-text);
}

.game-container__list {
    padding: 20px;
    min-height: 220px;
}

.game-container__header .game-action .action-navigation {
    display: flex;
    align-items: center;
}

.game-container__header .game-action .action-navigation .btn-custom-sm.swiper-button-disabled {
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: var(--page-text);
}


.game-container__header .game-action .action-navigation .btn-custom-sm {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    border-radius: 25px;
}

.game-container__list .games-holder .games-img {
    background: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.games-holder .games-img {
    background: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    height: 100%;

}


.games-holder .games-img img {
    width: 100%;
    height: max-content;

    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
}

.games-holder .hover-play {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    border-radius: 10px 10px 0 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.games-holder .hover-play .fas {
    color: var(--c-white);
    font-size: 20px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 50px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.games-holder.swiper-slide-active .games-bottom {
    border-top: 2px solid var(--secondary-color);
}

.games-holder .main .games-bottom {
    background: var(--c-black);
    color: var(--c-white);
    font-size: 14px;
    padding: 5px 8px;
    font-weight: 600;
}

.games-holder .games-bottom .info-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.home .home__game .game-container__list .games-holder:hover .hover-play {
    transform: scale(1);
    transition: all 0.2s ease-in-out;
}

.games-holder .hover-play {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    border-radius: 10px 10px 0 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.slide-border {
    background: transparent;
    padding: 2px;
    border-radius: 5px;
}

.slide-item {
    background: var(--primary-color);
    border-radius: 5px;
    position: relative;
    background: rgba(255, 255, 255, 0.1);
}

.slide-border .slide-item .slide-img img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center;
    object-position: center;
    padding: 5px;
}

.slide-border .slide-item .slide-img h6 {
    text-align: center;
    margin-bottom: 0;
    color: var(--c-white);
    font-size: 14px;
    padding: 8px;
    font-weight: 600;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.slide-border .slide-item .slide-img .hover-play {
    background: rgba(0, 0, 0, 0.7);
    color: var(--c-white);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transform: scale(0);
    transition: all 0.2s ease-in-out;
}

@media screen and (max-width: 320px) {
    .home__jackpot .jackpot-gif .jackpot-amount {
        font-size: 7.4px !important;
        font-family: advanced_dot_digital-7regular;
        top: 6px !important;
    }
}